import "./App.css";
import React, { useEffect, useState } from "react";

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import CaptionsPage from "./components/Router/CaptionsPage";
import PickPage from "./components/Router/PickPage";
import PhotoBoxPage from "./components/PhotoBoxPage";
import OffertPage from "./components/Router/OffertPage";
import RegistrationForm from "./components/admin/RegisterPage";
import LoginForm from "./components/admin/LoginPage";
import { AuthProvider } from "./components/admin/AuthContext";
import Dashboard from "./components/admin/Dashboard";
import Mail from "./components/Mail";
import ReactGA from 'react-ga';
import TagManager from "react-gtm-module";
import { BrowserRouter } from "react-router-dom/cjs/react-router-dom.min";
import CookieConsest from "./components/CookieConsest";
import { useCookies } from "react-cookie";
import ProductsComponent from "./components/admin/ProductsComponent";

function App() {
  const currentDomain = window.location.hostname;
  const [cookieConsest, removeCookie] = useCookies(['cookie-consest']);

  useEffect(() => {
    if (!cookieConsest['cookie-consest']) {
      document.body.style.overflow = 'hidden';
    } 

    if (cookieConsest['cookie-consest']) {
      if (cookieConsest['cookie-consest-selection'] !== 'all')  {
        if (!cookieConsest['cookie-consest-selection'].includes("stats")) {
          removeCookie('_gcl_au');
          removeCookie('_ga_*');
          removeCookie('_ga');
        }

        if (!cookieConsest['cookie-consest-selection'].includes("marketing")) {
          removeCookie('test_cookie');
        }
      }
    }
  }, [])
  return (
    <Router>
       {!cookieConsest['cookie-consest'] && <CookieConsest />}

      <AuthProvider>
        <Switch>
          
          {currentDomain === 'fotodziupla.pl' ? (
            <Route path="/" exact component={PickPage} />
          ) : (
            <Route path="/" exact component={PickPage} />
          )}
          <Route path="/mail" component={Mail} />
          <Route path="/register" component={RegistrationForm} />
          <Route path="/login" component={LoginForm} />
          <Route path="/dashboard" component={Dashboard} />
          <Route path="/napisy" component={CaptionsPage} />
          <Route path="/napisy-na-wynajem" component={CaptionsPage} />
          <Route path="/napisy-na-wynajem/" component={CaptionsPage} />
          <Route path="/fotodziupla" component={PhotoBoxPage} />
          <Route path="/offert" component={OffertPage} />
          <Route path="/" component={PickPage} />
          <Route path="*" component={PickPage} />
        </Switch>
      </AuthProvider>
    </Router>

    
  );
}

export default App;
