import React, { useEffect, useState } from 'react';
import useProductStore from '../useProductStore';
import useModalStore from '../useModalStore';

const OffertPRoductComponent = ({ product }) => {
  const { setProduct, setProductDisplayName } = useProductStore();
  const { setModalState } = useModalStore();
  const [ price, setPrice ] = useState('');

    useEffect(() => {
      if(typeof(product.price) === 'number') {
        setPrice(product.price + ' PLN');
      } else {
        let buildPrice = '';
        for (let i = 0; i < product.price.length; i++) 
        {
          const currentPrice = product.price[i];
          buildPrice += currentPrice.time + 'h - ' + currentPrice.price + ' PLN <br/>';
        }

        setPrice(buildPrice);
      }
    }, []);
    
  const bookHandle = (product) => {
    setProduct(product.name);
    setProductDisplayName(product.displayName);
    setModalState(true);
    
    
    // const element = document.getElementById('contact');

    // if (element) {
    //   element.scrollIntoView({
    //     behavior: 'smooth',
    //     block: 'start',    
    //   });
    // } 
  };

  return (
    <div className="row offert-row">
      <div className="col s12 m12 l12">
        <img className="responsive-img" src={product.photo} alt={product.name} />
      </div>
      <div className='row'>
      <div className="col s12 m6 l6 description">
        <h4 className="display-name">{product.displayName}</h4>
        <div className='price-container' dangerouslySetInnerHTML={{ __html: price }}></div>
        {product.img_price && <img className="responsive-img" src={product.img_price} alt={product.name} />}
        <button
          className="btn-large waves-effect waves-light teal custom-button w100 book "
          data-product={product.name}
          onClick={() => bookHandle(product)}
        >
          ZAREZERWUJ
        </button>
      </div>
      <div className='col s12 m6 l6 description'>
        <p className="light">{product.desc}</p>
        {product.img_desc && <> <h4 className="display-name">&nbsp;</h4><img className="responsive-img" src={product.img_desc} alt={product.name} /></>}
      </div>
      </div>
    </div>
  );
};

export default OffertPRoductComponent;
