import React, { useEffect, useState } from 'react'
import M from 'materialize-css';
import useProductStore from './useProductStore';
import axios from 'axios';

const ContatComponent = ({ answer }) => {
  const [products, setProducts] = useState([]);
  const { pickedProduct, setProduct } = useProductStore();
  const [emailData, setEmailData] = useState({
    last_name: '',
    email: '',
    subject: '',
    message: '',
    date: '',
    place: '',
    product: ''
  });

  useEffect(function () {
    const selectElement = document.getElementById('product');

    M.FormSelect.init(selectElement);
  }, [pickedProduct]);



const handleChange = (e) => {
    const { name, value } = e.target;
    setEmailData((prevData) => ({
        ...prevData,
        [name]: value,
    }));
};

const handleEmailSubmit = async (e) => {
    e.preventDefault();

    try {
        const response = await axios.post('https://api.blazestudioart.pl/api/send-email', JSON.stringify(emailData), {
            headers: {
                'Content-Type': 'application/json',
            },
        });

        console.log(response.data.message); // Handle the response accordingly
        M.toast({ html: 'E-mail zosał wysłany. Wkrótce się z Tobą skontaktujemy', classes: 'success-toast' })

        e.target.reset()
    } catch (error) {
      M.toast({ html: 'Problem z wysłaniem maila! ' + error.message, classes: 'error-toast' })
    }
};


  useEffect(function () {
    setProducts([
      {
        displayName: 'Fotodziupla',
        name: 'fotodziupla',
      },
      {
        displayName: 'Fotodziupla 360',
        name: 'fotodziupla_360',
      },
      {
        displayName: 'Fotobudka',
        name: 'fotobudka',
      },
      {
        displayName: 'Napis Miłość led',
        name: 'milosc_led',
      },
      {
        displayName: 'Napis Miłość Neon ',
        name: 'milosc_neon',
      },
      {
        displayName: 'Napis Miłość pisana drut',
        name: 'milosc_pisana_drut',
      },
      {
        displayName: 'Napis Wesele',
        name: 'napis_wesele',
      },
      {
        displayName: 'Napis Love ',
        name: 'love',
      },
      {
        displayName: 'Cyfry',
        name: 'cyfry',
      },
      {
        displayName: 'Ścianka do zdjęć',
        name: 'scianka_do_zdjec',
      },
      {
        displayName: 'Podest do zdjęć',
        name: 'podest_do_zdjec',
      },
      {
        displayName: 'Box',
        name: 'box',
      },
      {
        displayName: 'Księżyc',
        name: 'ksiezyc',
      },
      {
        displayName: 'Huśtawka',
        name: 'hustawka',
      },
      {
        displayName: 'Wyrzutnie Iskier',
        name: 'wyrzutnie_iskier',
      },
      {
        displayName: 'Ciężki dym ',
        name: 'ciezki_dym',
      }
    ]);
  }, []);

  useEffect(() => {
    const selectElement = document.getElementById('product');

    M.FormSelect.init(selectElement);
  }, [products]);

  const [messageBody, setMessageBody] = useState({})

  const handleSubmit = async (e) => {
    e.preventDefault();

    M.toast({ html: 'Problem z wysłaniem maila! Spróbuj ze swojego klienta pocztowego', classes: 'error-toast' })
  }

  // const handleChange = (e) => {
  //   setProduct(e.target.value);
  //   const selectElement = document.getElementById('product');

  //   M.FormSelect.init(selectElement);
  // };

  return (
    <div className="container" id="contact">
      <div className="section">
        <div className="row">
          <div className="col s12">
            <h3><i className="mdi-content-send brown-text"></i></h3>
            <h4 className="center">Kontakt</h4>

            <div className="row">

              <form className='col s12 m7' onSubmit={handleEmailSubmit}>
                <h5>Napisz do Nas</h5>
                <p>Marzysz o wyjątkowej dekoracji na swoje urodziny czy wesele? Poznaj naszą firmę, specjalistę w
                  dziedzinie wynajmu napisów podświetlanych! Tworzymy niepowtarzalne i stylowe napisy, które dodadzą
                  magii Twojemu wydarzeniu.</p>
                <p>
                  Nasze podświetlane napisy to doskonały sposób, aby nadać imprezie niezapomniany charakter. Bez względu
                  na to, czy świętujesz urodziny, czy planujesz wymarzone wesele, nasze napisy podświetlane sprawią, że
                  każdy moment stanie się jeszcze bardziej wyjątkowy.</p>
                <p className="phone-numbers">
                  +48 607846741 lub +48 665 679 767
                </p>

                <p className="just-mail">
                  <a href='mailto:kontakt@fotodziupla.pl'>kontakt@napisynawynajem.pl</a>
                </p>

                <div className="row">
                  <div className="input-field col s12 m6 l6">
                    <input id="last_name" type="text" className="validate" name="last_name" required onChange={handleChange}/>
                    <label htmlFor="last_name">Imię i nazwisko (wymagane)</label>
                  </div>
                  <div className="input-field col s12 m6 l6">
                    <input id="email" type="email" className="validate" name="email" required onChange={handleChange}/>
                    <label htmlFor="email">Adres e-mail (wymagane)</label>
                  </div>
                </div>
                <div className="row">
                  <div className="input-field col s12 m6 l6">
                    <input id="subject" type="text" className="validate" name="subject" required onChange={handleChange}/>
                    <label htmlFor="subject">Temat (wymagane)</label>
                  </div>
                  <div className="input-field col s12 m6 l6">
                    <input id="date" type="date" className="validate" name="date" onChange={handleChange}/>
                    <label htmlFor="date">Data (opcjonalnie)</label>
                  </div>
                </div>
                <div className="row">
                  <div className="input-field col s12 m6 l6">
                    <input id="place" type="text" className="validate" name="place" onChange={handleChange}/>
                    <label htmlFor="place">Miejsce imprezy </label>
                  </div>
                  <div className="input-field col s12 m6 l6">
                    <select id="product" className="validate" name="product" value={pickedProduct} onChange={handleChange}>
                      <option value={-1}>wybierz</option>
                      {products.map((product) => (
                        <option key={product.name} value={product.name}>
                          {product.displayName}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="row">
                  <div className="input-field col s12">
                    <textarea id="message" className="materialize-textarea" name="message" required onChange={handleChange}></textarea>
                    <label htmlFor="message">Treść wiadomości</label>
                  </div>
                </div>
                <div className="row">
                  <button className="btn-large waves-effect waves-light teal  custom-button">Wyślij</button>
                </div>
              </form>
              <div className="col s12 m5 ">
                <div className="contact-img"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  )
}

export default ContatComponent