import axios from 'axios';
import React, { useState } from 'react';

const Mail = () => {
    const [emailData, setEmailData] = useState({
        to: '',
        subject: '',
        message: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setEmailData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleEmailSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post('http://127.0.0.1:8000/api/send-email', JSON.stringify(emailData), {
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            console.log(response.data.message); // Handle the response accordingly
        } catch (error) {
            console.error('Error sending email:', error.message);
        }
    };

    return (
        <form onSubmit={handleEmailSubmit}>
            <label>To:</label>
            <input type="email" name="to" value={emailData.to} onChange={handleChange} required />

            <label>Subject:</label>
            <input type="text" name="subject" value={emailData.subject} onChange={handleChange} required />

            <label>Message:</label>
            <textarea name="message" value={emailData.message} onChange={handleChange} required />

            <button type="submit">Send Email</button>
        </form>
    );
};

export default Mail;
