import React, { useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons'
import M from 'materialize-css/dist/js/materialize'

const HeaderComponent = () => {
  
  useEffect(() => {
    var sidenav = document.querySelectorAll(".sidenav");
    M.Sidenav.init(sidenav, {});
  }, [ ]);

  return (
    <nav className="" role="navigation">
        <div className="nav-wrapper container">
          <a id="logo-container" href="#" className="brand-logo">
            <img src="../img/logo.png" alt="logo" id="logo" />
            <img src="img/logo.png" alt="logo" id="mobile-logo" />
          </a>
          <ul className="right hide-on-med-and-down">
            <li>
              <a className="draw-border" href="#about-us">O NAS</a>
            </li>
            <li>
              <a className="draw-border" href="#offert" data-target="our-products"
              >NASZE PRODUKTY</a>
            </li>
            <li><a className="draw-border" href="#faq">FAQ</a></li>

            <li>
              <a className="draw-border" href="#gallery">GALERIA</a>
            </li>
            <li>
              <a className="draw-border" href="/fotodziupla">FOTODZIUPLA</a>
            </li>
            <li>
              <a className="draw-border" href="#contact">KONTAKT</a>
            </li>
            <li className="multiple-icons">
              <a href="https://www.facebook.com/napisynawynajem/" target='_blank' rel='noreferrer'><FontAwesomeIcon icon={faFacebook} /></a>
              <a href="https://www.instagram.com/napisynawynajem/" target='_blank' rel='noreferrer'><FontAwesomeIcon icon={faInstagram} /></a></li>
          </ul>

          <ul id="nav-mobile" className="sidenav">
            <li><a href="#about-us">O NAS</a></li>
            <li><a href="#offert">NASZE PRODUKTY</a></li>
            <li><a href="#gallery">GALERIA</a></li>
            <li>
              <a className="draw-border" href="/fotodziupla">FOTODZIUPLA</a>
            </li>
            <li><a href="#faq">FAQ</a></li>
            <li><a href="#contact">KONTAKT</a></li>
          </ul>
          <a href="#" data-target="nav-mobile" className="sidenav-trigger"><i className="material-icons">menu</i></a>
        </div>
      </nav>
  )
}

export default HeaderComponent