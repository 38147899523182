import React, { useEffect, useState } from 'react'
import OffertPRoductComponent from './OffertProductComponent';
import BookModal from '../BookModal';

const OffertProductsComponent = () => {
  const [products, setProducts] = useState([]);
  const [visibleProducts, setVisibleProducts] = useState(5);

  useEffect(() => {
  setProducts([
    {
      displayName: 'Fotodziupla',
      name: 'fotodziupla',
      price: [],
      photo: 'img/Nasze produkty/fotodziupla/Toscania  (268).jpg',
      desc: '',
      img_desc: 'img/Nasze produkty/fotodziupla/fotodziupla_opis.jpg',
      img_price: 'img/Nasze produkty/fotodziupla/fotodziupla_cennik.jpg'
    },
    {
      displayName: 'Fotodziupla 360',
      name: 'fotodziupla_360',
      price: [
        { time: 2,
          price: 1100
        },
        {
          time: 3,
          price: 1300
        }
      ],
      photo: 'img/Nasze produkty/Fotodziupla 360/fotodziupla_360.jpg',
      desc: 'Fotobudka 360 to obrotowa platforma do tworzenia boomerangów, gifów i filmów slow motion, idealnych na Instagramowe i TikTokowe rolki czy relacje!'
    },
    {
      displayName: 'Klasyczna fotobudka',
      name: 'klasyczna_fotobudka',
      price: [
        { time: 2,
          price: 900
        },
        {
          time: 3,
          price: 1100
        }
      ],
      photo: 'img/Nasze produkty/fotobudka.jpg',
      desc: 'Fotobudka jest przenośnym urządzeniem umożliwiającym wykonywanie serii zdjęć, które następnie są drukowane na miejscu i wydawane w kilkanaście sekund po zakończeniu sesji zdjęciowej.'
    },
    {
      displayName: 'Napis Miłość Retro',
      name: 'milosc_retro',
      price: 500,
      photo: 'img/Nasze produkty/napisy/milosc_retro/milosc_retro.jpg',
      desc: `Najpopularniejszy napis z pośród naszej oferty.\n\r
      Miłość retro  wykonana jest z MDF i sklejki, lakierowana bezbarwnie.
      Wysokość napisu to 120 cm a szerokość ok 600 cm
      Zasilanie 230V żarówki o barwie ciepłej , możliwość ściemniania napisu.
      Ściemniacz dostarczany z napisem.`
    },
    {
      displayName: 'Napis Miłość led',
      name: 'milosc_led',
      price: 500,
      photo: 'img/Nasze produkty/napisy/led/milosc_led.jpg',
      desc: 'Napis LED RGB z opcja zmiany kolorów. Sterowne pilotem. Zasilanie 230V. \n\rMiłość led wykonana ze sklejki oraz mlecznej plexy która swieci w ok 90% swojej powierzchni'
    },
    {
      displayName: 'Napis Miłość Neon ',
      name: 'milosc_neon',
      price: 600,
      photo: 'img/foto_video/foto/neon.jpg',
      desc: 'Napis wykonany z drutu pomalowany w kolorze czarnym.Na Obrzeżach każdej z liter przymocowany Led w ciepłej barwie.Wysokość ok 120 cm po rozłożeniu szerokość ok 600 cm Zasilanie 230V.Brak możliwości ściemniania napisu.'
    },
    {
      displayName: 'Napis Miłość pisana drut',
      name: 'milosc_pisana_drut',
      price: 600,
      photo: 'img/Nasze produkty/napisy/milosc_pisana_drut/Targi (778)_websize.jpg',
      desc: 'Napis Miłość w czcionce pisanej umieszczony na stalowej kracie. Led w barwie neutralnej zasilanie 230V wysokość ok 120 cm szerokość 650 cm. Brak możliwości ściemniania napisu.'
    },
    {
      displayName: 'Napis Wesele',
      name: 'napis_wesele',
      price: 500,
      photo: 'img/Nasze produkty/napisy/Wesele/Toscania  (274).jpg',
      desc: `Napis wykonany jest z MDF i sklejki, lakierowany bezbarwnie.
      Wysokość napisu to 120 cm a szerokość ok 600 cm
      Zasilanie 230V, żarówki o barwie ciepłej, możliwość ściemniania napisu.
      Ściemniacz dostarczany z napisem.`
    },
    {
      displayName: 'Napis Love ',
      name: 'love',
      price: 350,
      photo: 'img/Nasze produkty/napisy/Love/Love.jpg',
      desc: 'Napis Love z żarówkami o ciepłej barwie z możliwością ściemniania napisu. Wysokość ok 120 cm szerokość ok 400 cm. Love wykonany ze sklejki i lakierowany bezbarwnie.Ściemniacz dostarczamy z napisem.'
    },
    {
      displayName: 'Napis You & Me',
      name: 'you_and_me',
      price: 500,
      photo: 'img/foto_video/foto/y..jpg',
      desc: 'Napis YOU & ME z żarówkami o ciepłej barwie z możliwością ściemniania napisu. Wysokość ok 120 cm szerokość ok 600 cm. Napis wykonany ze sklejki i lakierowany bezbarwnie.Ściemniacz dostarczamy z napisem.'
    },
    {
      displayName: 'Cyfry 18,40,50',
      name: 'cyfry',
      price: 300,
      photo: 'img/Nasze produkty/napisy/Cyfry/3.jpg',
      desc: 'Wszystkie cyfry wykonane ze sklejki ,lakierowane w kolorze orzechu wysokość 120 cm szerokość od 40 cm do 80cm. Żarówki o barwie ciepłej.Możliwość ściemniania cyfr.\r\nŚciemniacz dostarczany z napisem Zasilanie 230V'
    },
    {
      displayName: 'Ścianka do zdjęć',
      name: 'scianka_do_zdjec',
      price: 400,
      photo: 'img/Nasze produkty/napisy/scianka_do_zdjec/_DSC2111.jpg',
      desc: 'Okrągła ścianka wykonana ze stali z elementami kraty w kolorze czarnym.W zestawie napis Miłość.\r\nSzerokość ok 300 cm wysokość ok 250 cm Zasilanie 230V Napis o barwie ciepłej brak możliwości zamontowania ściemniacza'
    },
    {
      displayName: 'Podest do zdjęć',
      name: 'podest_do_zdjec',
      price: 500,
      photo: 'img/Nasze produkty/podest_do_zdjec/2.jpg',
      desc: 'Podest do zdjęć w kolorze białym o rozmiarze 280 cm . Liście w kolorze białym .Kompozycja ułożenia liści dowolna.'
    },
    {
      displayName: 'Box',
      name: 'box',
      price: 1000,
      photo: 'img/Nasze produkty/box_do_zdjec/1.jpg',
      desc: 'Box do zdjęć o szerokości 200 cm i wysokości 300 cm wykonany w środku z lameli , sztucznych kwiatów oraz podłogi z paneli drewnianych. Na tylnej ścianie możliwość  powieszenia  dowolnego  napisu neonowego.'
    },
    {
      displayName: 'Księżyc',
      name: 'ksiezyc',
      price: 700,
      photo: 'img/Nasze produkty/ksiezyc/9582A056-BE17-4A7B-87FE-8367F894616F.JPG',
      desc: `Księżyc wykonany ze sklejki pomalowany w kolorze złotym o wysokości ok 250 cm i szerokości ok 180 cm.\r\n
Zasilanie 230V żarówki o barwie ciepłej , możliwość ściemniania żarówek.\r\n
Ściemniacz dostarczany z napisem.\r\n
Na księżycu zamontowana ławeczka dla dwóch osób, brak możliwości demontażu ławeczki.`
    },
    {
      displayName: 'Huśtawka',
      name: 'hustawka',
      price: 400,
      photo: 'img/Nasze produkty/hustawka/46836911_573200166456680_5414108869938905088_n.jpg',
      desc: ''
    },
    {
      displayName: 'Wyrzutnie Iskier (4 szt.)',
      name: 'wyrzutnie_iskier',
      price: 350,
      photo: 'img/Nasze produkty/Wyrzutnie iskier/1...jpg',
      desc: `Wyrzutnie iskier najczęściej stosowane do pierwszego tańca lub dekoracji przy torcie .\r\n
Wysokość iskier od 2 do 6 m kolorze srebrnym lub złotym .`
    },
    {
      displayName: 'Ciężki dym ',
      name: 'ciezki_dym',
      price: 550, 
      photo: 'img/foto_video/foto/ciezki_dym.jpg',
      desc: `Stosowany podczas pierwszego tańca lub przy torcie .\r\n
Ciężki dym na bazie suchego lodu.`
    }
  ]);
}, []);

const handleLoadMore = () => {
  const remainingProducts = products.length - visibleProducts;
  const nextBatch = Math.min(remainingProducts, 5); // Load up to 10 images at a time

  setVisibleProducts(visibleProducts + nextBatch);
};

  return (
    <div className="container" id="offert">
          <BookModal />

    <div className="section">
      <div className="row">
        <div className="col s12">
          <h3><i className="mdi-content-send brown-text"></i></h3>
          <img src='/img/logo.png' alt='logo' className='center-logo'/>
          <h4 className="center">Nasze produkty</h4>
          <div id="products">
          {
              products.slice(0, visibleProducts).map((product, index) => (
                <OffertPRoductComponent product={product} key={product.name} />
              ))}
          </div>


          <div className="row center-btn">

          
          {visibleProducts < products.length ? <button className="btn-large waves-effect waves-light teal  custom-button" onClick={handleLoadMore}>ZAŁADUJ WIĘCEJ</button> : ''}
        </div>
        </div>
      </div>
    </div>
  </div>
  )
}

export default OffertProductsComponent    