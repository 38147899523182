import axios from 'axios';
import React, { useEffect, useRef, useState } from 'react'
const ProductComponent = ({ product, onSubmit }) => {
  const [formData, setFormData] = useState({
    name: product ? product.name : '',
    display_name: product ? product.display_name : '',
    prices: product ? product.prices : [{ time: '', price: '' }],
    photo: null,
    img_description: null,
    img_price: null,
  });

  const nameRef = useRef(null);
  const display_nameRef = useRef(null);
  const photoRef = useRef(null);
  const img_descriptionRef = useRef(null);
  const img_priceRef = useRef(null);

  useEffect(() => {
    // Update refs when formData changes
    if (nameRef.current) {
      nameRef.current.value = formData.name;
    }
    if (display_nameRef.current) {
      display_nameRef.current.value = formData.display_name;
    }
    // Note: You can't directly assign files to the ref like this
    // You need to update your input value when the ref changes
    // photoRef.current.files[0] = formData.photo;
    // img_descriptionRef.current.files[0] = formData.img_description;
    // img_priceRef.current.files[0] = formData.img_price;
  }, [formData]);

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const updatedPrices = [...formData.prices];
    updatedPrices[index][name] = value;

    setFormData((prevData) => ({
      ...prevData,
      prices: updatedPrices,
    }));
  };

  const handleAddPrice = () => {
    setFormData((prevData) => ({
      ...prevData,
      prices: [...prevData.prices, { time: '', price: '' }],
    }));
  };

  const handleRemovePrice = (index) => {
    const updatedPrices = [...formData.prices];
    updatedPrices.splice(index, 1);

    setFormData((prevData) => ({
      ...prevData,
      prices: updatedPrices,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = new FormData();
    data.append('name', nameRef.current.value);
    data.append('display_name', display_nameRef.current.value);

    // Append prices
    formData.prices.forEach((price, index) => {
      data.append(`prices[${index}][time]`, price.time);
      data.append(`prices[${index}][price]`, price.price);
    });

    data.append('photo', photoRef.current.files[0]);
    data.append('img_description', img_descriptionRef.current.files[0]);
    data.append('img_price', img_priceRef.current.files[0]);

    try {
      const response = product
        ? await axios.put(`your-api-endpoint/products/${product.id}`, data, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
        : await axios.post('your-api-endpoint/products', data, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          });

      console.log('Product saved:', response.data.product);

      if (onSubmit) {
        onSubmit();
      }
    } catch (error) {
      console.error('Error saving product:', error);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      {/* Add your form fields */}
      <div className='row'>
          <div className='input-field col s12'>
          <input type="text" name="name" defaultValue={nameRef.current} onChange={(e) => nameRef.current = e.target.value} required />
            <label for='email'>Nazwa produktu</label>
          </div>
        </div>
    <input type="text" name="display_name" defaultValue={display_nameRef.current} onChange={(e) => display_nameRef.current = e.target.value} placeholder="Display Name" required />

    {/* Prices */}
    {formData.prices.map((price, index) => (
      <div key={index} className='row'>
        <input className='col s12 m6 l6' type="number" name={`prices[${index}][time]`} defaultValue={price.time} onChange={(e) => handleInputChange(e, index)} placeholder="Time" required />
        <input className='col s12 m6 l6' type="number" name={`prices[${index}][price]`} defaultValue={price.price} onChange={(e) => handleInputChange(e, index)} placeholder="Price" required />
        {formData.prices.length > 1 && <button type="button" onClick={() => handleRemovePrice(index)}>Remove</button>}
      </div>
    ))}
    <button type="button" onClick={handleAddPrice}>Add Another Price</button>
      {/* ... (other input fields) */}

      {/* Image upload fields */}
      <div className='row'>
        <div className="file-field input-field">
          <div className="btn">
            <span>Zdjecie produktu</span>
            <input
              type="file"
              ref={photoRef}
              onChange={(e) => setFormData({ ...formData, photo: e.target.files[0] })}
              accept="image/*"
              required
              name="photo"
            />
          </div>
          <div className="file-path-wrapper">
            <input className="file-path validate" type="text" />
          </div>
        </div>
      </div>

      <div className='row'>
        <div className="file-field input-field">
          <div className="btn">
            <span>Opis w formie zdjecia (opcjonalnie)</span>
            <input type="file" ref={img_descriptionRef.current} onChange={(e) => img_descriptionRef.current = e.target.files[0]} accept="image/*" required name='img_description'/>
            </div>
            <div className="file-path-wrapper">
              <input className="file-path validate" type="text" />
            </div>
          </div>
      </div>

      <div className='row'>
        <div className="file-field input-field">
          <div className="btn">
            <span>Cennik w formie zdjecia (opcjonalnie)</span>
            <input type="file" ref={img_priceRef.current} onChange={(e) => img_priceRef.current = e.target.files[0]} accept="image/*" required name='img_price'/>
            </div>
            <div className="file-path-wrapper">
              <input className="file-path validate" type="text" />
            </div>
          </div>
      </div>

      <button type="submit">Submit</button>
    </form>
  );
};

export default ProductComponent;