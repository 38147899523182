import React, { useEffect, useState } from "react";
import M from 'materialize-css';
import HeaderComponent from "./SubpageHeaderComponent";
import FooterComponent from "./FooterComponent";
import BookModal from "./BookModal";
import ProductComponent from "./ProductComponent";

const PhotoBoxPage = () => {
    const [loadingProgress, setLoadingProgress] = useState(0);
    const [isLoaded, setIsLoaded] = useState(false);
    const [products, setProducts] = useState([]);

    useEffect(() => {
      setProducts([{
        displayName: 'Fotodziupla',
        name: 'fotodziupla',
        price: [],
        photo: 'img/Nasze produkty/fotodziupla/Toscania  (268).jpg',
        desc: `W naszej ofercie znajdziecie foto budke, w naszym wykonaniu to FotoDziupla, klimatyczne miejsce stworzone od zera
        w formie pryczepy. Do środka wchodzicie wraz z rodziną lub/i znajomymi, aby uatrakcyjnić zdjęcia możecie zabrac ze
        soba gadżety, które dla Was przygotowalismy.
        Nasza obsługa przeszkoli Was z BHP, SEP, UDT, PSP i juz zaczynamy sie bawic :)
        A tak na serio, po wejściu do Dziupli, poza robionymi zdjęciami juz nic nie musi być serio. Wchodzicie, dajecie czadu i
        bawicie się świetnie. Efektem tego jest pamiątka w postaci wydrukowanych zdjęć i wpisem do Księgi Gości.`,
        img_desc: 'img/Nasze produkty/fotodziupla/fotodziupla_opis.jpg',
        img_price: 'img/Nasze produkty/fotodziupla/fotodziupla_cennik.jpg'
      },
      {
        displayName: 'Fotodziupla 360',
        name: 'fotodziupla_360',
        price: [
          { time: 2,
            price: 1100
          },
          {
            time: 3,
            price: 1300
          }
        ],
        photo: 'img/Nasze produkty/Fotodziupla 360/fotodziupla_360.jpg',
        desc: 'Fotobudka 360 to obrotowa platforma do tworzenia boomerangów, gifów i filmów slow motion, idealnych na Instagramowe i TikTokowe rolki czy relacje!'
      },
      {
        displayName: 'Klasyczna fotobudka',
        name: 'klasyczna_fotobudka',
        price: [
          { time: 2,
            price: 900
          },
          {
            time: 3,
            price: 1100
          }
        ],
        photo: 'img/Nasze produkty/fotobudka.jpg',
        desc: 'Fotobudka jest przenośnym urządzeniem umożliwiającym wykonywanie serii zdjęć, które następnie są drukowane na miejscu i wydawane w kilkanaście sekund po zakończeniu sesji zdjęciowej.'
      }]);
    }, []);

    useEffect(() => {
        const images = document.images;
        const totalImages = images.length;
        let loadedImages = 0;

        M.AutoInit();
    
        const imgLoaded = () => {
          loadedImages += 1;
          const percentage = ((100 / totalImages) * loadedImages).toFixed(0);
          setLoadingProgress(percentage);
    
          if (loadedImages === totalImages) {
            doneLoading();
          }
        };
    
        const doneLoading = () => {
          // Simulate hiding the overlay and showing additional content after loading
          setTimeout(() => {
            setIsLoaded(true);
    
            // Your logic to reveal additional content goes here
    
          }, 1200);
        };
    
        // Attach onLoad and onError event handlers to each image
        Array.from(images).forEach((img) => {
          const newImage = new Image();
          newImage.onload = imgLoaded;
          newImage.onerror = imgLoaded;
          newImage.src = img.src;
        });
    
      }, []);
    

  return (
    <>
    <div>
    {!isLoaded  && (
      <div id="overlay">
        <div id="progstat">Ładowanie {loadingProgress > 0 ? loadingProgress + '%' : ''}</div>
        <div id="progress" style={{ width: `${loadingProgress}%` }}></div>
      </div>
    )}
      <HeaderComponent />
      <main id="photobox-container">
        <div id="index-banner" className="parallax-container">
          <div className="section no-pad-bot">
            <div className="container center-parallax-content">
              <br />
              <br />
              <h1 className="header center teal-text text-lighten-2">
              </h1>
              <div className="row center"></div>
              <div className="row center">
                <a
                  href="#photobox-description"
                  id="download-button"
                  className="btn-large waves-effect waves-light teal lighten-1"
                >
                  POZNAJ OFERTĘ
                </a>
              </div>
              <br />
              <br />
            </div>
          </div>
          <div className="parallax">
            <img src="img/fotodziupla.jpg" alt="Unsplashed background img 1" />
          </div>
        </div>
        <div className="container section" id="photobox-description">
          <h5 className="header">Dlaczego Fotobudka to Must-Have na Twoim Wydarzeniu?</h5>
          <div id="why-photobox">
            <ul className="browser-default">
              <li><b>Angażująca Rozrywka:</b> Goście pokochają możliwość zrobienia sobie oryginalnych zdjęć, które natychmiast otrzymają w formie wydruku lub udostępnienia online.</li>
              <li><b>Promocja Wydarzenia w Sieci:</b> Zdjęcia z naszych fotobudek szybko stają się viralem w mediach społecznościowych, zwiększając zasięg i popularność Twojego wydarzenia.</li>
              <li><b>Niepowtarzalne Wspomnienia:</b> Fotobudka to doskonały sposób na stworzenie unikalnych wspomnień, które goście z pewnością docenią.</li>
              <li><b>Najwyższa Jakość Zdjęć:</b> Wykorzystujemy najnowocześniejsze technologie, aby zapewnić najwyższą jakość zdjęć. Nasze urządzenia są wyposażone w profesjonalne aparaty, co gwarantuje ostrość i kolorystykę na najwyższym poziomie.</li>
            </ul>
          </div>
          <h5 className="header">Oferta Wynajmu Fotobudki - Dodaj Magiczny Element do Twojego Wydarzenia!</h5>
          <p>
          Zainwestuj w niezapomniane chwile i wzbogać swoje wydarzenie dzięki naszym usługom wynajmu fotobudki i fotobudki 360. Skontaktuj się z nami już dziś, a my dostosujemy ofertę do Twoich potrzeb.
          </p>
          <p>
          Fotobudka, którą proponujemy, to nie tylko urządzenie do robienia zdjęć. To interaktywna strefa, w której Twoi goście będą mogli uwiecznić najpiękniejsze chwile Waszego święta. Nasz sprzęt jest wyposażony w najnowocześniejszą technologię, gwarantującą klarowność i ostrość każdego ujęcia. Dzięki temu, zdjęcia z naszej fotobudki będą niezapomnianą pamiątką z Waszego wyjątkowego dnia.
          </p>
          <h5 className="header">Co wyróżnia naszą fotobudkę na tle innych?</h5>
          <p>Przede wszystkim bogata oferta personalizacji! Możemy dostosować wygląd zdjęć do motywu Waszego wesela, dodając ramki, napisy czy grafiki, które sprawią, że każda fotografia będzie unikalna. Warto podkreślić, że nasza fotobudka to nie tylko tradycyjne ujęcia – oferujemy również zabawne gadżety, dzięki którym każde zdjęcie nabierze charakteru i świeżości.</p>
          <p>Zapewniamy obsługę na najwyższym poziomie. Nasz doświadczony personel zatroszczy się o to, aby fotobudka działała bez zarzutu, a Wasze zdjęcia były perfekcyjne. W razie jakichkolwiek pytań czy wątpliwości, nasi profesjonalni operatorzy służą pomocą, byście mogli skupić się wyłącznie na radości z tego wyjątkowego dnia.</p>
        </div>

        <div className="container" id="offert">
        <BookModal />

    <div className="section">
      <div className="row">
        <div className="col s12">
          <h3><i className="mdi-content-send brown-text"></i></h3>
          <h4 className="center">Nasze produkty</h4>
          <div id="products">
          {
             products.map((product, index) => (
              <ProductComponent product={product} key={product.name} />
          ))
             }
          </div>
        </div>
      </div>
    </div>
  </div>
      </main>
      <FooterComponent />
      {/* <FullCalendar
        plugins={[dayGridPlugin]}
        initialView="dayGridMonth"
        events={[
          { title: "event 1", date: "2019-04-01" },
          { title: "event 2", date: "2019-04-02" },
        ]}
      /> */}
    </div>
  </>
  )
}

export default PhotoBoxPage