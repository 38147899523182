import React, { useEffect, useState } from "react";
import M from 'materialize-css';
import HeaderComponent from "../HeaderComponent";
import AboutComponent from "../AboutComponent";
import ProductsComponent from "../ProductsComponent";
import FaqComponent from "../FaqComponent";
import GalleryComponent from "../GalleryComponent";
import ContatComponent from "../ContactComponent"
import FooterComponent from "../FooterComponent";

const CaptionsPage = () => {
    const [loadingProgress, setLoadingProgress] = useState(0);
    const [isLoaded, setIsLoaded] = useState(false);
  
    useEffect(() => {
      const section = sessionStorage.getItem('section');
      const element = document.getElementById(section);

      setTimeout(function() {
      if (element) {
        element.scrollIntoView({
          behavior: 'smooth',
          block: 'start',    
        });
      } 

      setTimeout(function() {
        sessionStorage.removeItem('section')
      }, 1500)
    }, 500);
    }, [])
    // useEffect(() => {
    //   const handleLoad = () => {
    //     setLoading(false);
    //   };
  
    //   const handleProgress = (event) => {
    //     if (event.lengthComputable) {
    //       const loaded = (event.loaded / event.total) * 100;
    //       setProgress(Math.round(loaded));
    //     }
    //   };
  
    //   M.AutoInit();
  
    //   window.addEventListener("load", handleLoad);
  
    //   const xhr = new XMLHttpRequest();
    //   xhr.open("GET", window.location.href, true);
    //   xhr.onprogress = handleProgress;
    //   xhr.send();
  
    //   return () => {
    //     window.removeEventListener("load", handleLoad);
    //   };
    // }, []);

    useEffect(() => {
        const images = document.images;
        const totalImages = images.length;
        let loadedImages = 0;

        M.AutoInit();
    
        const imgLoaded = () => {
          loadedImages += 1;
          const percentage = ((100 / totalImages) * loadedImages).toFixed(0);
          setLoadingProgress(percentage);
    
          if (loadedImages === totalImages) {
            doneLoading();
          }
        };
    
        const doneLoading = () => {
          // Simulate hiding the overlay and showing additional content after loading
          setTimeout(() => {
            setIsLoaded(true);
    
            // Your logic to reveal additional content goes here
    
          }, 1200);
        };
    
        // Attach onLoad and onError event handlers to each image
        Array.from(images).forEach((img) => {
          const newImage = new Image();
          newImage.onload = imgLoaded;
          newImage.onerror = imgLoaded;
          newImage.src = img.src;
        });
    
      }, []);
    

  return (
    <>
    <div>
    {!isLoaded  && (
      <div id="overlay">
        <div id="progstat">Ładowanie {loadingProgress > 0 ? loadingProgress + '%' : ''}</div>
        <div id="progress" style={{ width: `${loadingProgress}%` }}></div>
      </div>
    )}
      <HeaderComponent />
      <main>
        <div id="index-banner" className="parallax-container">
          <div className="section no-pad-bot">
            <div className="container center-parallax-content">
              <br />
              <br />
              <h1 className="header center teal-text text-lighten-2">
              </h1>
              <div className="row center"></div>
              <div className="row center">
                <a
                  href="#offert"
                  id="download-button"
                  className="btn-large waves-effect waves-light teal lighten-1"
                >
                  POZNAJ OFERTĘ
                </a>
              </div>
              <br />
              <br />
            </div>
          </div>
          <div className="parallax">
            <img src="img/home.jpg" alt="Unsplashed background img 1" />
          </div>
        </div>

        <AboutComponent />
        <ProductsComponent />
        <FaqComponent />
        <GalleryComponent />
        <ContatComponent />
      </main>
      <FooterComponent />
      {/* <FullCalendar
        plugins={[dayGridPlugin]}
        initialView="dayGridMonth"
        events={[
          { title: "event 1", date: "2019-04-01" },
          { title: "event 2", date: "2019-04-02" },
        ]}
      /> */}
    </div>
  </>
  )
}

export default CaptionsPage