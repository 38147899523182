import React, { createContext, useContext, useEffect, useState } from 'react';
import axios from 'axios';
import config from '../config';
import { useCookies } from 'react-cookie';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [cookies, setCookie] = useCookies(['access_token'])

  useEffect(() => {
    const storedUser = sessionStorage.getItem('user');
    
    if (storedUser) {
      setUser(JSON.parse(storedUser));
    }
  }, []);

  const login = async (formData) => {
    try {
      const response = await axios.post(`${config.API_BASE_URL}/login`, formData);
      sessionStorage.setItem('user', JSON.stringify(response.data.user));
      const access_token = response.data.authorisation.token;

      setUser(response.data.user); // Assuming the API returns user data

      axios.defaults.headers.common['Authorization'] = `Bearer ${access_token}`;

      let expires = new Date();
      expires.setTime(expires.getTime() * 1000);
      setCookie('access_token', access_token, { path: '/',  expires})
      
    } catch (error) {
      console.error(error.response); // Handle error
    }
  };

  const logout = () => {
    sessionStorage.removeItem('user');
    sessionStorage.removeItem('session');
    setUser(null);
  };

  return (
    <AuthContext.Provider value={{ user, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
