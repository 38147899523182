import React from 'react'

const AboutComponent = () => {
  return (
    <div className="container" id="about-us">
    <div className="section">
      <div className="row">
        <h4 className="center">O nas</h4>

        <div className="col s12 m6">
          <div className="icon-block">
            <img
              src="img/o_nas/about.jpg"
              alt="o-nas"
              className="full-w-img about-img"
            />
          </div>
        </div>

        <div className="col s12 m6">
          <div className="icon-block">
            <p className="light">
              Siema! Tu Dariusz i Mateusz
              Od 6 lat tworzymy projekt pod szyldem Napisy Na Wynajem oraz FotoDziupla.
              Na początku naszej działalności stworzyliśmy jeden napis podświetlany Miłość Retro, zainteresowanie wynajmem było tak duże, że w kolejnym sezonie doszły następne napisy. Dziś posiadamy cały alfabet liter oraz wszystkie cyfry. W naszej ofercie znajdziecie również inne dekoracje, m.in.: FotoDziuplę, FotoBudkę stacjonarną, VideoBudkę 360stopni, oryginalny mobilny bar pod szyldem - KonioWóz.</p>
              <p className="light">
              Dzięki naszemu zapleczu możemy stworzyć dowolne produkty na Waszą uroczystość.
              Z roku na rok rozwijamy się i tworzymy nowe projekty, aby baza produktów, które tworzymy trafiała do jak największego grona naszych potencjalnych Klientów.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
  )
}

export default AboutComponent