import React, { useState } from 'react'
import { Gallery } from "react-grid-gallery";
import { Puff } from 'react-loader-spinner';
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";

const images = [
  { src: 'img/foto_video/foto/0901BE2F-9745-4860-9B8D-4868A99FFAF0.JPG' },
  { src: 'img/foto_video/foto/1 2.jpg' },
  { src: 'img/foto_video/foto/1...jpg' },
  { src: 'img/foto_video/foto/1...jpg.jpg' },
  { src: 'img/foto_video/foto/1.jpg' },
  { src: 'img/foto_video/foto/18 OLIWIERA (22) copy.jpg' },
  { src: 'img/foto_video/foto/18Tomka(157).jpg' },
  { src: 'img/foto_video/foto/18Tomka(160).jpg'},
  { src: 'img/foto_video/foto/18Tomka(327).jpg'},
  { src: 'img/foto_video/foto/2.jpg'},
  { src: 'img/foto_video/foto/231B7AF5-E0B8-4C7B-84C8-2E85AB21094D.JPG'},
  { src: 'img/foto_video/foto/3 2.jpg'},
  { src: 'img/foto_video/foto/3.jpg'},
  { src: 'img/foto_video/foto/4a7d904f-bd2c-4467-87d8-dd3fe82b7dda.JPG'},
  { src: 
  'img/foto_video/foto/5.jpg'},
  { src: 
  'img/foto_video/foto/559FEF79-83D9-46A1-BB88-91B8AA241E47.JPG'},
  { src: 
  'img/foto_video/foto/55CFBA35-AB04-4C09-923E-0F719EDA21D7-kopia.JPG'},
  { src: 
  'img/foto_video/foto/6.jpg'},
  { src: 
  'img/foto_video/foto/86870673_911017339341626_5806393010748915712_o.JPG'},
  { src: 
  'img/foto_video/foto/9.jpg'},
  { src: 
  'img/foto_video/foto/9582A056-BE17-4A7B-87FE-8367F894616F.JPG'},
  { src: 
  'img/foto_video/foto/B24615EF-5D4C-4EC6-96B4-E2CE09573BDA.JPG'},
  { src: 
  'img/foto_video/foto/ciezki_dym.jpg'},
  { src: 
  'img/foto_video/foto/D6ADD759-F6A2-4FB9-A4B4-5448E9BD9E50.JPG'},
  { src: 
  'img/foto_video/foto/DDC5762F-7E90-4389-BA60-1DA6EA52A0DE.JPG'},
  { src: 
  'img/foto_video/foto/f.......jpg'},
  { src: 
  'img/foto_video/foto/f....jpg'},
  { src: 
  'img/foto_video/foto/FOTODZIUPLA.jpg'},
  { src: 
  'img/foto_video/foto/IMG_5963.JPG'},
  { src: 
  'img/foto_video/foto/J_J (859).jpg'},
  { src: 
  'img/foto_video/foto/l...jpg'},
  { src: 
  'img/foto_video/foto/Love.jpg'},
  { src: 
  'img/foto_video/foto/m.....jpg'},
  { src: 
  'img/foto_video/foto/mati brzezniak .jpg'},
  { src: 
  'img/foto_video/foto/mati_brzezniak.jpg'},
  { src: 
  'img/foto_video/foto/neon.jpg'},
  { src: 
  'img/foto_video/foto/milosc_led_2.JPG'},
  { src: 
  'img/foto_video/foto/milosc_led.jpg'},
  { src: 
  'img/foto_video/foto/milosc_neon.jpg'},
  { src: 
  'img/foto_video/foto/ml.....jpg'},
  { src: 
  'img/foto_video/foto/ml....jpg'},
  { src: 
  'img/foto_video/foto/P+B109 2.jpg'},
  { src: 
  'img/foto_video/foto/P+B109.jpg'},
  { src: 
  'img/foto_video/foto/s....jpg'},
  { src: 
  'img/foto_video/foto/Targi (411)_websize.jpg'},
  { src: 
  'img/foto_video/foto/Toscania  (168).jpg'},
  { src: 
  'img/foto_video/foto/Toscania  (193).jpg'},
  { src: 
  'img/foto_video/foto/Toscania  (259).jpg'},
  { src: 
  'img/foto_video/foto/Toscania  (268).jpg'},
  { src: 
  'img/foto_video/foto/w...jpg'},
  { src: 
  'img/foto_video/foto/w.jpg'},
  { src: 
  'img/foto_video/foto/y..jpg'}
  ];

  const GalleryComponent = () => {
    const [currentIndex, setIndex] = useState(-1);
    const [visibleImages, setVisibleImages] = useState(10);
    const [loading, setLoading] = useState(false);

    const handleClick = (index) => {
      setIndex(index);
    };

    const handleLoadMore = () => {
        setLoading(true);

        const remainingImages = images.length - visibleImages;
        const nextBatch = Math.min(remainingImages, 10); // Load up to 10 images at a time

        setTimeout(() => {
        setVisibleImages(visibleImages + nextBatch);
        setLoading(false)
        }, 500);
      };
  
      const loadMoreButton = images.length > visibleImages && (
        loading ? <Puff
        visible={true}
        height="80"
        width="80"
        color="gold"
        ariaLabel="puff-loading"
        wrapperStyle={{}}
        wrapperClass="center"
        /> :
        <button className='btn-large waves-effect waves-light teal  custom-button' onClick={handleLoadMore}>więcej zdjęć</button>
      );

    return (
      <div className="container" id="gallery">
        <div className="section">
          <div className="row">
            <div className="col s12">
              <h3><i className="mdi-content-send brown-text"></i></h3>
              <h4 className="center">Galeria</h4>
              <div className="col s12 m12 center">
                <div className="gg-container">
                  <div className="gg-box dark col5" data-theme="dark">
                  {images.slice(0, visibleImages).map((item, index) => (
                      <img
                        alt="gallery-item"
                        key={index}
                        onClick={() => handleClick(index)}
                        className={`gallery-link ${index === currentIndex ? 'selected' : ''}`}
                        src={item.src}
                        data-src={item.src}
                      />
                    ))}
                  
                    <Lightbox
                      slides={images.map((item) => ({ src: item.src }))}
                      open={currentIndex >= 0}
                      index={currentIndex}
                      close={() => setIndex(-1)}
                    />
                  </div>
                </div>
                <div>
                    {loadMoreButton}
                    </div>
              </div>
            </div>
          </div>
        </div>

        <div className="section" id="video">
        <div className="row">
          <div className="col s12">
            <h3><i className="mdi-content-send brown-text"></i></h3>
            <h4 className="center">Video</h4>
            <div className="col s12 m12 center">
              <div className="col s12 m12 l6">
                <video src="img/foto_video/video/timelaps_wesele.mp4#t=0.1" controls></video>
              </div>
              <div className="col s12 m12 l6">
                <video src="img/foto_video/video/milosc_1a.mp4#t=0.1" controls></video>
              </div>
              <div className="col s12 m12 l6">
                <video src="img/foto_video/video/Napisy.mp4#t=0.1" controls></video>
              </div>
              <div className="col s12 m12 l6">
                <video src="img/foto_video/video/wesele_1a.mp4#t=0.1" controls></video>
              </div>
              <div className="col s12 m12 l6">
                <video src="img/foto_video/video/360.mp4#t=0.1" controls></video>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>
    );
  };

export default GalleryComponent 