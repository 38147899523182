import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { useAuth } from './AuthContext';
import M from 'materialize-css';
import axios from 'axios';
import config from '../config';
import DataTable from 'react-data-table-component';
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faTimes, faCheck, faClock } from '@fortawesome/free-solid-svg-icons';
import Moment from 'react-moment';
import { useCookies } from 'react-cookie';
import { wait } from '@testing-library/user-event/dist/utils';
import { Link, Route, HashRouter as Router, Switch, useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import ProductsComponent from './ProductsComponent';
import { useLocation } from 'react-router-dom';


const Dashboard = () => {
    const location = useLocation();
    const history = useHistory();

    const { user, logout } = useAuth();
    const [data, setBookings] = useState([]);
    const [tableData, setTableData] = useState({});
    const [cookies, setCookie] = useCookies(['access_token']);

    const [confirmed, setConfirmed] = useState(0);
    const [rejected, setRejected] = useState(0);
    const [waiting, setWaiting] = useState(0);
    const [bookings, setBookingsAmount] = useState(0);

    const [isLoggedIn, setIsLoggedIn] = useState(true);

    useEffect(() => {
        localStorage.setItem('lastVisitedPath', location.pathname);
      }, [location.pathname]);


      useEffect(() => {
        const lastVisitedPath = localStorage.getItem('lastVisitedPath');
    
        if (lastVisitedPath) {
          history.replace(lastVisitedPath);
        }
      }, [history]);
    
      
    useEffect(() => {
        const checkTokenExpiry = () => {
          const token = cookies.access_token;
          if (token) {
            const decodedToken = JSON.parse(atob(token.split('.')[1])); 
            const currentTime = Math.floor(Date.now() / 1000); 
    
            if (decodedToken.exp < currentTime) {
              setIsLoggedIn(false);
              logout();
              localStorage.removeItem('jwtToken');
            }
          }
        };
    
        // Check token expiry every minute (adjust as needed)
        const intervalId = setInterval(checkTokenExpiry, 60000);
    
        // Clear the interval on component unmount
        return () => clearInterval(intervalId);
      }, []);

    useEffect(() => {
        axios.defaults.headers.common['Authorization'] = `Bearer ${cookies.access_token}`;

        if (!sessionStorage.getItem('user')) {
            setIsLoggedIn(false);
        }
    }, [])

    const handleDelete = async (bookingId) => {
        const confirmation = window.confirm(`Czy na pewno skasować rezerwację?`);

        if (confirmation) {
            try {
                await axios.get(`${config.API_BASE_URL}/delete-booking/${bookingId}`);
                fetchData();
            } catch (error) {
                console.error('Error deleting booking:', error);
            }
        }
    };

    const handleReject = async (bookingId) => {
        const confirmation = window.confirm(`Czy na pewno odrzucić rezerwację?`);

        if (confirmation) {
            try {
                await axios.get(`${config.API_BASE_URL}/reject-booking/${bookingId}`);
                fetchData();
            } catch (error) {
                console.error('Error rejecting booking:', error);
            }
        }
    };


    const handleConfirm = async (bookingId) => {
        const confirmation = window.confirm(`Czy na pewno potwierdzić rezerwację?`);

        if (confirmation) {
            try {
                await axios.get(`${config.API_BASE_URL}/confirm-booking/${bookingId}`);
                fetchData();
            } catch (error) {
                console.error('Error confirming booking:', error);
            }
        }
    };


    const columns = [
        {
            name: 'Data',
            selector: row => row.book_start_date,
            sortable: true,
            cell: (row) => (
                <Moment format="YYYY-MM-DD">
                    {row.book_start_date}
                </Moment>
            )
        },
        {
            name: 'Produkt',
            selector: row => row.product_name,
            sortable: true,
        },
        {
            name: 'Zamawiający',
            selector: row => row.purchaser_name,
            sortable: true,
        },
        {
            name: 'Miejsce imprezy',
            selector: row => row.party_place,
            sortable: true,
        },
        {
            name: 'Email',
            selector: row => row.purchaser_email,
            sortable: true,
        },

        {
            name: 'Zgłoszenie rezerwacji',
            sortable: true,
            cell: (row) => (
                <Moment format="YYYY-MM-DD HH:mm">
                    {row.created_at}
                </Moment>
            )
        },
        {
            name: '',
            width: '50px',
            cell: (row) => (
                <>
                    {row.rejected ? <FontAwesomeIcon icon={faTimes} /> : ''}
                    {row.confirmed ? <FontAwesomeIcon icon={faCheck} /> : ''}

                    {!row.rejected && !row.confirmed ? <FontAwesomeIcon icon={faClock} /> : ''}
                </>
            ),
        },
        {
            name: '',
            cell: (row) => (
                <div className='table-actions'>
                    {(!row.rejected && !row.confirmed) && <>
                        <button className='waves-effect waves-light btn-small green-custom' onClick={() => handleConfirm(row.id)} title="Potwierdź">
                            <i className='material-icons'>check_circle</i>
                        </button>
                        <button className='waves-effect waves-light btn-small orange-custom ' onClick={() => handleReject(row.id)} title='Odrzuć'>
                            <i className='material-icons'>cancel</i>
                        </button>
                    </>
                    }
                    <button className='waves-effect waves-light btn-small red-custom' title='Skasuj' onClick={() => handleDelete(row.id)}>
                        <i className='material-icons'>delete</i>
                    </button>
                </div>
            ),
        },
    ];

    useEffect(() => {
        if (isLoggedIn && sessionStorage.getItem('user')) {
            fetchData();
        } else {
            history.push('login')
        }

        let sidenav = document.querySelector('.sidenav');
        M.Sidenav.init(sidenav, {});
    }, []);

    const fetchData = async () => {
        try {
            const access_token = sessionStorage.getItem('session');

        if (access_token) {
          axios.defaults.headers.common['Authorization'] = `Bearer ${access_token}`;
        } else {
           // logout();
        }

            // const response = await axios.get(`${config.API_BASE_URL}/get-bookings`);

        
            const response = await axios.get(`${config.API_BASE_URL}/get-bookings`);

            if (response.data)
                setBookings(response.data);

            setBookingsAmount(response.data.length);
            
            const rejected = response.data.filter(booking => booking.rejected === 1).length;
            const confirmed = response.data.filter(booking => booking.confirmed === 1).length;

            setRejected(rejected);
            setConfirmed(confirmed);
            setWaiting(response.data.length - (rejected+confirmed));

            const data = response.data;
            setTableData({
                columns,
                data
            });
        } catch (error) {
        } finally {
        }

    };


    // Redirect to login if the user is not authenticated
    if (!user) {
        return <Redirect to="/login" />;
    }

    if (!isLoggedIn) {
       return <p>You have been logged out due to inactivity.</p>;
     }

    // Render admin content
    return (
        // <div className='container serction'>
        //     <h2>Witaj, {user.name}!</h2>
        //     <a href='' onClick={() => logout()}>wyloguj</a>

        //     <DataTableExtensions {...tableData}>
        //         <DataTable
        //             columns={columns}
        //             data={data}
        //             noHeader
        //             defaultSortField="id"
        //             defaultSortAsc={false}
        //             pagination
        //             highlightOnHover
        //         />

        //     </DataTableExtensions>
        // </div>

        <main id="dashboard">
        <nav className="blue darken-3">
	<div className="nav-wrapper">
		<a href="#!" className="sidenav-trigger brand-logo left" data-target="slide-out"><i className="material-icons" style={{marginLeft: '5px'}}>menu</i>Logo</a>
		<ul className="right hide-on-med-and-down">
			<li><a href="sass.html"><i className="material-icons">search</i></a></li>
			<li><a href="badges.html"><i className="material-icons">view_module</i></a></li>
			<li><a href="collapsible.html"><i className="material-icons">refresh</i></a></li>
			<li><a href="mobile.html"><i className="material-icons">more_vert</i></a></li>
		</ul>
	</div>
</nav>
<ul id="slide-out" className="sidenav sidenav-fixed sidenav-close">
	<li>
		<div className="user-view">
			<div className="background">
				<img src="https://c4.wallpaperflare.com/wallpaper/876/437/102/blue-material-material-design-minimal-art-wallpaper-preview.jpg" />
			</div>
			<a href="#user"><img className="circle" src="https://icon-library.com/images/google-user-icon/google-user-icon-21.jpg" /></a>
			<a href="#name"><span className="white-text name">{user.name}</span></a>
			<a href="#email"><span className="white-text email">{user.email}</span></a>
            <a href='' onClick={() => logout()}>wyloguj</a>
		</div>
	</li>
	<li><Link className="waves-effect" to="/dashboard"><i className="material-icons">home</i>Panel</Link></li>
	<li><Link className="waves-effect" to="/dashboard/products"><i className="material-icons">inventory_2</i>Produkty</Link></li>
    <li><a className="waves-effect" href="#!"><i className="material-icons">calendar_month</i>Rezerwacje</a></li>
    <li><a className="waves-effect" href="#!"><i className="material-icons">text_snippet</i>Zawartość strony</a></li>
    <li><a className="waves-effect" href="#!"><i className="material-icons">collections</i>Galeria</a></li>
    <li><a className="waves-effect" href="#!"><i className="material-icons">smart_display</i>Video</a></li>
    <li><a className="waves-effect" href="#!"><i className="material-icons">contact_mail</i>Kontakt i social media</a></li>
    <li><a className="waves-effect" href="#!"><i className="material-icons">quiz</i>FAQ</a></li>
	{/* <li>
		<div className="divider"></div>
	</li> */}
	{/* <li><a className="subheader">Subheader</a></li>
	<li><a className="waves-effect" href="#!"><i className="material-icons">link</i>Link</a></li>
	<li><a className="waves-effect" href="#!"><i className="material-icons">link</i>Link</a></li>
	<li><a className="waves-effect" href="#!"><i className="material-icons">link</i>Link</a></li>
	<li><a className="waves-effect" href="#!"><i className="material-icons">link</i>Link</a></li>
	<li><a className="waves-effect" href="#!"><i className="material-icons">link</i>Link</a></li>
	<li><a className="waves-effect" href="#!"><i className="material-icons">link</i>Link</a></li>
	<li><a className="waves-effect" href="#!"><i className="material-icons">link</i>Link</a></li> */}
</ul>
<main id="dashboard-content">
    <Switch>
        <Route path="/dashboard/products" component={ProductsComponent} />
        {/* Add more nested routes for other components */}
      </Switch>
<div className='row'>
    <div className='col'>
        <h4 className='header-no-space'>Rezerwacje</h4>
    </div>
</div>
<div className="row">
  
	<div className="col s12 m6">
		<div className="row">
			<div className="col s12 m6">
				<div className="card blue darken-1">
					<div className="card-content white-text">
						<span className="card-title">{ bookings }</span>
						<p>Rezerwacji </p>
					</div>
				</div>
			</div>
			<div className="col s12 m6">
				<div className="card grey darken-1">
					<div className="card-content white-text">
						<span className="card-title">{ waiting }</span>
						<p>Oczekujących</p>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div className="col s12 m6">
        <div className="row">
			<div className="col s12 m6">
				<div className="card green darken-1">
					<div className="card-content white-text">
						<span className="card-title">{ confirmed }</span>
						<p>Potwierdzonych </p>
					</div>
				</div>
			</div>
			<div className="col s12 m6">
				<div className="card red darken-1">
					<div className="card-content white-text">
						<span className="card-title">{ rejected }</span>
						<p>Odrzuconych</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
<div className='row'>
    <div className='col'>
        <h4 className='header-no-space'>Zarobki</h4>
    </div>
</div>
<div className="row">
	<div className="col s12 m4">
		<div className="card teal darken-1">
			<div className="card-content white-text">
				<span className="card-title">923, 281, 917</span>
				<p>Szacowane zarobki</p>
			</div>
		</div>

	</div>
	<div className="col s12 s12 m4">
		<div className="card lime darken-1">
			<div className="card-content white-text">
				<span className="card-title">555, 281, 133</span>
				<p>Biezący miesiąc</p>
			</div>
		</div>

	</div>
	<div className="col s12 m4">
		<div className="card blue darken-1">
			<div className="card-content white-text">
				<span className="card-title">923, 281, 917</span>
				<p>Biezący rok</p>
			</div>
		</div>

	</div>
</div>

     <DataTableExtensions {...tableData}> 
                 <DataTable
                    columns={columns}
                    data={data}
                    noHeader
                    defaultSortField="id"
                    defaultSortAsc={false}
                    pagination
                    highlightOnHover
                />

            </DataTableExtensions>
{/* <div className="row">
	<div className="col s12 m6">
		<div className="card white">
			<div className="card-content white-text">
				<span className="card-title text-black">My Todo list</span>
				<p>
					<label>
						<input type="checkbox" />
						<span>CODE</span>
					</label>
				</p>
				<p>
					<label>
						<input type="checkbox" />
						<span>CODE</span>
					</label>
				</p>
				<p>
					<label>
						<input type="checkbox" />
						<span>CODE</span>
					</label>
				</p>
				<p>
					<label>
						<input type="checkbox" />
						<span>CODE</span>
					</label>
				</p>
				<p>
					<label>
						<input type="checkbox" />
						<span>CODE</span>
					</label>
				</p>
				<p>
					<label>
						<input type="checkbox" />
						<span>CODE</span>
					</label>
				</p>
				<p>
					<label>
						<input type="checkbox" />
						<span>CODE</span>
					</label>
				</p>
			</div>
		</div>
	</div>
</div> */}
</main>

        </main>
    );
};

export default Dashboard;
