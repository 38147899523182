import React from 'react'

const AnswerComponent = ({answer}) => {
  return (
    <div className='faq-row'>
        <div className='question'>{answer.q}</div>
        <div className='answer'>{answer.a}</div>
    </div>
  )
}

export default AnswerComponent