import React, { useEffect, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import M from 'materialize-css';
import { ContentContainer } from "@fullcalendar/core/internal";
import HeaderComponent from "../HeaderComponent";
import ProductsComponent from "../ProductsComponent";
import FooterComponent from "../FooterComponent";
import { useHistory } from "react-router-dom";
import OffertProductsComponent from "../offert/OffertProductsComponent";

const OffertPage = () => {
    const [loadingProgress, setLoadingProgress] = useState(0);
    const [isLoaded, setIsLoaded] = useState(false);

    const history = useHistory(); 
  
    useEffect(() => {
        // Parse query parameters
        const urlParams = new URLSearchParams(window.location.search);
        const source = urlParams.get('source');
        const token = urlParams.get('token');
    
        // Check if the source and token match the expected values
        if (source !== 'qrcode' || token !== 'pNp!Y0n^MS1@G78*cQG!sSng') {
          history.push('/not-allowed');
        }
      }, [history]);
    // useEffect(() => {
    //   const handleLoad = () => {
    //     setLoading(false);
    //   };
  
    //   const handleProgress = (event) => {
    //     if (event.lengthComputable) {
    //       const loaded = (event.loaded / event.total) * 100;
    //       setProgress(Math.round(loaded));
    //     }
    //   };
  
    //   M.AutoInit();
  
    //   window.addEventListener("load", handleLoad);
  
    //   const xhr = new XMLHttpRequest();
    //   xhr.open("GET", window.location.href, true);
    //   xhr.onprogress = handleProgress;
    //   xhr.send();
  
    //   return () => {
    //     window.removeEventListener("load", handleLoad);
    //   };
    // }, []);

    useEffect(() => {
        const images = document.images;
        const totalImages = images.length;
        let loadedImages = 0;

        M.AutoInit();
    
        const imgLoaded = () => {
          loadedImages += 1;
          const percentage = ((100 / totalImages) * loadedImages).toFixed(0);
          setLoadingProgress(percentage);
    
          if (loadedImages === totalImages) {
            doneLoading();
          }
        };
    
        const doneLoading = () => {
          // Simulate hiding the overlay and showing additional content after loading
          setTimeout(() => {
            setIsLoaded(true);
    
            // Your logic to reveal additional content goes here
    
          }, 1200);
        };
    
        // Attach onLoad and onError event handlers to each image
        Array.from(images).forEach((img) => {
          const newImage = new Image();
          newImage.onload = imgLoaded;
          newImage.onerror = imgLoaded;
          newImage.src = img.src;
        });
    
      }, []);
    

  return (
    <>
    <div>
      <main>
        <OffertProductsComponent />
      </main>
      <FooterComponent />
      {/* <FullCalendar
        plugins={[dayGridPlugin]}
        initialView="dayGridMonth"
        events={[
          { title: "event 1", date: "2019-04-01" },
          { title: "event 2", date: "2019-04-02" },
        ]}
      /> */}
    </div>
  </>
  )
}

export default OffertPage