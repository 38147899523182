import React, { useEffect, useState } from 'react'
import '../../Choice.css';
import { useHistory  } from "react-router-dom";

const PickPage = () => {
    let history = useHistory(); 

    const handlePick = (page) => {  
         history.push(page);
    }

    return (
        <main id='choice-page'>
        <div className="choice draw-border" onClick={() =>handlePick('/napisy-na-wynajem')}>
                <img src="img/choice/love.JPG" alt='napisy-na-wynajem' />
                <span className="caption">
                    NAPISY NA WYNAJEM
                </span>
            </div>
            <div className="choice draw-border" onClick={() =>handlePick('/fotodziupla')}>
                <img src="img/choice/fotodziupla.jpg" alt='fotodziupla'/>
                <span className="caption">
                    FOTODZIUPLA
                </span>
            </div>
        </main>
    )
}

export default PickPage 