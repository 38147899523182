import React, { useEffect } from 'react';
import useProductStore from './useProductStore';
import useModalStore from './useModalStore';

const ProductComponent = ({ product }) => {
  const { setProduct, setProductDisplayName } = useProductStore();
  const { setModalState } = useModalStore();

  const bookHandle = (product) => {
    setProduct(product.name);
    setProductDisplayName(product.displayName);
    setModalState(true);

    // const element = document.getElementById('contact');

    // if (element) {
    //   element.scrollIntoView({
    //     behavior: 'smooth',
    //     block: 'start',    
    //   });
    // } w
  };

  return (
    <div className="row offert-row">
    <div className="col s12 m12 l12">
      <img className="responsive-img" src={product.photo} alt={product.name} />
    </div>
    <div className='row'>
    <div className="col s12 m6 l6 description">
      <h4 className="display-name">{product.displayName}</h4>
      <button
        className="btn-large waves-effect waves-light teal custom-button w100 book "
        data-product={product.name}
        onClick={() => bookHandle(product)}
      >
        ZAREZERWUJ
      </button>
    </div>
    <div className='col s12 m6 l6 description'>
      <p className="light">{product.desc}</p>
    </div>
    </div>
  </div>
  );
};

export default ProductComponent;
