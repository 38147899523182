import React, { useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons'
import M from 'materialize-css/dist/js/materialize'
import { useHistory  } from "react-router-dom";
import useSectionStore from './useSectionStore';

const HeaderComponent = () => {
  const {setSection} = useSectionStore();


  useEffect(() => {
    var sidenav = document.querySelectorAll(".sidenav");
    M.Sidenav.init(sidenav, {});
  }, [ ]);

  return (
    <nav className="" role="navigation">
        <div className="nav-wrapper container">
          <a id="logo-container" href="/fotodziupla#root" className="brand-logo">
            <img src="../img/logo_dziupla.png" alt="logo" id="logo" />
            <img src="img/logo_dziupla.png" alt="logo" id="mobile-logo" />
          </a>
          <ul className="right hide-on-med-and-down">
            <li>
              <a className="draw-border" href="/napisy-na-wynajem" onClick={() => sessionStorage.setItem("section", "about-us")}>O NAS</a>
            </li>
            <li>
              <a className="draw-border" href="#products"  onClick={() => sessionStorage.setItem("section", "offert")} data-target="our-products"
              >NASZE PRODUKTY</a>
            </li>
            <li><a className="draw-border" href="/napisy-na-wynajem"  onClick={() => sessionStorage.setItem("section", "faq")}>FAQ</a></li>

            <li>
              <a className="draw-border" href="/napisy-na-wynajem"  onClick={() => sessionStorage.setItem("section", "gallery")}>GALERIA</a>
            </li>
            <li>
              <a className="draw-border" href="/napisy-na-wynajem">NAPISY NA WYNAJEM</a>
            </li>
            <li>
              <a className="draw-border" href="/napisy-na-wynajem"  onClick={() => sessionStorage.setItem("section", "contact")}>KONTAKT</a>
            </li>
            <li className="multiple-icons">
              <a href="https://www.facebook.com/fotodziuplaleszno/" target='_blank' rel='noreferrer'><FontAwesomeIcon icon={faFacebook} /></a>
              <a href="https://www.instagram.com/fotodziupla/" target='_blank' rel='noreferrer'><FontAwesomeIcon icon={faInstagram} /></a></li>
          </ul>

          <ul id="nav-mobile" className="sidenav">
          <li>
              <a  href="/fotodziupla" onClick={() => sessionStorage.setItem("section", "about-us")}>O NAS</a>
            </li>
            <li>
              <a  href="#products"  onClick={() => sessionStorage.setItem("section", "offert")} data-target="our-products"
              >NASZE PRODUKTY</a>
            </li>
            <li><a  href="/napisy-na-wynajem"  onClick={() => sessionStorage.setItem("section", "faq")}>FAQ</a></li>

            <li>
              <a  href="/napisy-na-wynajem"  onClick={() => sessionStorage.setItem("section", "gallery")}>GALERIA</a>
            </li>
            <li>
              <a className="draw-border" href="/napisy-na-wynajem">NAPISY NA WYNAJEM</a>
            </li>
            <li>
              <a  href="/napisy-na-wynajem"  onClick={() => sessionStorage.setItem("section", "contact")}>KONTAKT</a>
            </li>
          </ul>
          <a href="#root" data-target="nav-mobile" className="sidenav-trigger"><i className="material-icons">menu</i></a>
        </div>
      </nav>
  )
}

export default HeaderComponent