import React from 'react'

const FooterComponent = () => {
  return (
    <footer className='page-footer'>
        <div className='footer-copyright'>
            <div className='container'>Napisy na wynajem | Leszno Copyright &copy; 2023</div>
        </div>
    </footer>
  )
}

export default FooterComponent  