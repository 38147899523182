import React, { useEffect, useState } from 'react'
import AnswerComponent from './AnswerComponent';
const questions = {
  "NAPISY": [
    {
      q: 'Czy dojeżdżacie w każdy zakątek Polski',
      a: ' Tak , po wcześniejszej rozmowie i uzgodnieniach dojeżdżamy !!!Tak, odezwij się do nas i ustalimy szczegóły.'
    },
    {
      q: 'Czy napisy mogą stać na zewnątrz',
      a: 'Tak mogą stać , natomiast jeśli pogoda jest kapryśna i zaczyna padać deszcz należy napis odłączyć od zasilania '
    },
    {
      q: 'Czy wysyłacie napisy kurierem ',
      a: 'Nie. Zawsze dowozimy napisy naszym transportem'
    },
    {
      q: 'Jaki jest czas wynajmu napisów oraz kiedy jesteście na miejscu',
      a: 'Jesteśmy na miejscu około 3/4 godzin przed imprezą najczęściej konsultujemy to z wynajmującym lub menagerem lokalu.Odbiór napisu od razu po imprezie lub nad ranem również po rozmowie.'
    },
    {
      q: 'Zasilanie i prąd',
      a: 'Do napisów potrzebujemy zasilnia 230V, przy dłuższych odległościach od źródła zasilania prosimy o poinformowanie nas o tym fakcie, wtedy zabierzemy ze sobą stosowne długie przedłużacze'
    },
    {
      q: 'Czy napisy mogą stać w każdym miejscu',
      a: 'Tak jeśli płaszczyzna jest w miarę prosta i stabilna (stawiamy na trawie , piasku, kostce brukowej na tyle aby napisy stały stabilnie , posiadamy tylne podpórki które uniemożliwiają przechył litery/cyfry do tyłu '
    }
  ],
  "FOTODZIUPLA I BUDKA 360": [
    {
      q: 'Na jakim obszarze działacie z FotoDziuplą',
      a: 'Działamy na terenie całej Polski'
    },
    {
      q: 'Czy akcesoria są na miejscu',
      a: 'Zarówno do fotodziupli jak i budki 360 dostępne są akcesoria które przywozimy ze sobą .Jeśli impreza jest tematyczna i macie ze sobą szczególne akcesoria nie mamy nic przeciwko'
    },
    {
      q: 'Zasilanie i prąd',
      a: 'Do zasilania naszej przyczepy i budki 360 potrzebujemy zasilania 230V , jeśli produkty mają stać daleko od źródła zasilania poinformuj nas wcześniej weźmiemy ze sobą stosowne przedłużacze'
    },
    {
      q: 'Czy wydruk w fotodziupli jest ograniczony',
      a: 'W naszej ofercie jest nieograniczony wydruk zdjęć'
    },
    {
      q: 'W jaki sposób przesyłane jest video z budki 360',
      a: 'Video z platformy 360 przesyłane jest od razu po zakończonej zabawie na telefon poprzez Airdrop, wiadomość sms ,mailem lub whatsappem. Ważna kwestia !! Do poprawnego przesyłania filmików na imprezie potrzebny jest dobry dostęp do internetu lub Wi-fi  .W razie słabe dostępu do internetu , filmy przesyłam dnia następnego zbiorczo na adres email organizatora uroczystości'
    },
    {
      q: 'Jak wcześniej przyjeżdżacie na usługę',
      a: 'Na miejscu jesteśmy około 1.5h przed zaplanowanym startem'
    },
    {
      q: 'Jakie imprezy obsługujecie',
      a: 'Tak naprawdę każdą uroczystość od urodzin po wesela i imprezy firmowe'
    },
    {
      q: 'Ile miejsca potrzebujecie do rozłożenia',
      a: 'Budka 360 potrzebuje ok 3x3 m ,natomiast foto dziupla stoi na zewnątrz ok 6x4m , weź pod uwagę że jest to przyczepa i tego miejsca trochę potrzebujemy, ale zawsze znajdziemy na to odpowiednie miejsce czy to jest teren zielony , miejsce przed wejściem na salę lub parking'
    },
    {
      q: 'Czy do obsługi są przydzielone osoby',
      a: 'W zależności od potrzeb do obsługi delegujemy jedną lub dwie osoby'
    }
  ]
};

const FaqComponent = () => {
  const [mainCats, setMainCats] = useState([]);

  useEffect(function() {
    setMainCats(Object.keys(questions)); 
  }, []);
  
  return (
    <div className="container" id="faq">
    <div className="section">
      <div className="row">
        <div className="col s12">
          <h3><i className="mdi-content-send brown-text"></i></h3>
          <h4 className="center">FAQ</h4>
          <div id="questions-answers-container">
          {mainCats.map((cat) =>  (
              <div key={cat}>
                <h5 className='subheader'>{cat}</h5>
                {questions[cat].map((answer, index) => 
                  <AnswerComponent answer={answer} key={index}/>
                )}
              </div>
            ))}
          </div>

        </div>
      </div>
    </div>
  </div>
  )
}

export default FaqComponent 