import { faCookieBite } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react'
import { useCookies } from 'react-cookie';

const CookieConsest = () => {
    const [marketingChecked, setMarketingChecked] = useState(true); 
    const [statsChecked, setStatsChecked] = useState(true); 

    const [showNecessary, setShowNecessary] = useState(false);
    const [showStats, setShowStats] = useState(false);
    const [showMarketing, setShowMarketing] = useState(false);

    const [cookieConsest, setCookieConsest] = useCookies(['cookie-consest']);
    const [cookieConsestSelection, setCookieConsestSelection] = useCookies(['cookie-consest-selection']);

    const showDetails = (module) => {
        switch (module) {
            case 'necessary':
                setShowMarketing(false);
                setShowStats(false);
                setShowNecessary(true);
                break;
                case 'stats':
                    setShowMarketing(false);
                    setShowStats(true);
                    setShowNecessary(false);
                    break;
                    case 'marketing':
                        setShowMarketing(true);
                        setShowStats(false);
                        setShowNecessary(false);
                        break;
                        default:
                            break;
        }
    }

    const saveSelected = () => {
        const expires = (new Date(new Date().getTime()+1000*60*60*24*365));
        setCookieConsest('cookie-consest', true, { path: '/',  expires})

        let cookiesSelection = '';
        if (statsChecked)
            cookiesSelection += 'stats|';

        if (marketingChecked)
            cookiesSelection += 'marketing|';

        setCookieConsestSelection('cookie-consest-selection', cookiesSelection, { path: '/',  expires})
    }

    const saveAll = () => {
        const expires = (new Date(new Date().getTime()+1000*60*60*24*365));
        setCookieConsest('cookie-consest', true, { path: '/',  expires})
        setCookieConsestSelection('cookie-consest-selection', 'all', { path: '/',  expires})
    }

  return (
    <div id='cookie-overflow'>
    <div id="cookie-consest">
        <FontAwesomeIcon icon={faCookieBite} />
        <div className="info">
        <h4>
        Zgoda na ciasteczka
        </h4>
        <p>
        Korzystamy z plików cookie w celu zapewniania efektywnej obsługi oraz dokonania oceny jej realizacji, dążąc do możliwie najlepszych doświadczeń naszych użytkowników. Kliknięcie w "Zaakceptuj" oznacza zgodę na używanie wszystkich plików cookie.
        </p>
        </div>

 <div className="switches-container">
 
  
    <div className="cookie-switch-container" onClick={() => showDetails("necessary")}>
      <span>Niezbędne</span> 
      
      <div className="switch">
        <label>
          <input type="checkbox" disabled checked />
          <span className="lever"></span>
        </label>
      </div>
    </div>
    
    <div className="cookie-switch-container" data-target="stats" onClick={() => showDetails("stats")}>
      <span>Statystyki</span> 
      
      <div className="switch">
        <label>
          <input type="checkbox" {...statsChecked ? 'checked' : ''} data-target="stats"  defaultChecked={statsChecked} onChange={() => setStatsChecked((state) => !state)}/>
          <span className="lever"></span>
        </label>
      </div>
    </div>
    
    <div className="cookie-switch-container" data-target="marketing" onClick={() => showDetails("marketing")}>
      <span>Marketing</span> 
      
      <div className="switch">
        <label>
          <input type="checkbox" {...marketingChecked ? 'checked' : ''}  data-target='marketing' defaultChecked={marketingChecked} onChange={() => setMarketingChecked((state) => !state)}/>
          <span className="lever"></span>
        </label>
      </div>
    </div>
    
      
    </div>
    <div className="cookie-content">
    {showNecessary && <div id="necessary">
    Niezbędne pliki cookie pomagają w użytkowaniu strony internetowej poprzez aktywowanie podstawowych funkcji, takich jak nawigacja po stronie i dostęp do zabezpieczonych obszarów strony. Strona internetowa nie może funkcjonować prawidłowo bez tych plików cookie.
    </div>
    }
    {showStats && 
     <div id="stats">
        Statystyczne pliki cookie pomagają właścicielom stron internetowych zrozumieć, w jaki sposób odwiedzający wchodzą w interakcję z witryną, zbierając i dostarczając informacje w sposób anonimowy.
    </div>
    }
    {showMarketing && <div id="marketing">
Marketingowe pliki cookie są wykorzystywane do śledzenia osób odwiedzających strony internetowe. Intencją jest wyświetlanie reklam, które są istotne i angażujące dla indywidualnego użytkownika, a zatem bardziej wartościowe dla wydawców i reklamodawców zewnętrznych
    </div> }
 </div>
    <div className="buttons-container" id="allow-selected">
      <button className="waves-effect waves-light btn bordered" onClick={() => saveSelected()}>
         Zapisz wybrane
      </button>
        <button className="waves-effect waves-light btn gold" id="allow-all" onClick={() => saveAll()}>
         Akceptuję wszystkie
      </button>
    </div>
</div>
</div>
  )
}

export default CookieConsest