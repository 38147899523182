import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useAuth } from './AuthContext';
import { useHistory } from 'react-router-dom';

const LoginForm = () => {
    const { user, login, logout } = useAuth();
    const history = useHistory();   
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };



  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
        await login(formData);
        history.push('/dashboard');
      } catch (error) {
        console.error(error.response.data); // Handle error
      }
       
  };

  return (
<main>
<center>
  <img className="responsive-img" style={{width: 250}} src="img/logo.png" />
  <div className="section"></div>

  <h5 className="indigo-text">Logowanie</h5>
  <div className="section"></div>

  <div className="container">
    <div className="z-depth-1 grey lighten-4 row" style={{display: 'inline-block', padding: '32px 48px 0px 48px', border: '1px solid #EEE', minWidth: '400px'}}>

      <form className="col s12" onSubmit={handleSubmit}>
        <div className='row'>
          <div className='col s12'>
          </div>
        </div>

        <div className='row'>
          <div className='input-field col s12'>
            <input className='validate' type='email' name='email' id='email' value={formData.email} onChange={handleChange}/>
            <label for='email'>E-mail</label>
          </div>
        </div>

        <div className='row'>
          <div className='input-field col s12'>
            <input className='validate' type='password' name='password' id='password' value={formData.password} onChange={handleChange} />
            <label for='password'>Hasło</label>
          </div>
        </div>

        <br />
        <center>
          <div className='row'>
            <button type='submit' name='btn_login' className='col s12 btn btn-large waves-effect indigo'>Login</button>
          </div>
        </center>
      </form>
    </div>
  </div>
</center>

<div className="section"></div>
<div className="section"></div>
</main>
  );
};

export default LoginForm;
