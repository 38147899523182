import config from '../config';
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component'
import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ProductComponent from './ProductComponent';


const ProductsComponent = () => {
    const [tableData, setTableData] = useState({});
    const [data, setProducts] = useState([]);
    const [addOrEdit, setAddOrEDit] = useState(false);
    const columns = [
        {
            name: 'Nazwa',
            selector: row => row.name,
            sortable: true,
        },
        {
            name: 'Nazwa wyświetlana',
            selector: row => row.display_name,
            sortable: true,
        },
        {
            name: 'Cena',
            selector: row => row.price,
            sortable: true,
        },
        {
            name: '',
            cell: (row) => (
                <div className='table-actions'>
                  
                </div>
            ),
        },
    ];


    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const access_token = sessionStorage.getItem('session');

        if (access_token) {
          axios.defaults.headers.common['Authorization'] = `Bearer ${access_token}`;
        } else {
           // logout();
        }

            const response = await axios.get(`${config.API_BASE_URL}/get-products`);

            if (response.data)
                setProducts(response.data.products);

            const data = response.data.products;
            setTableData({
                columns,
                data
            });
        } catch (error) {
        } finally {
        }

    };
  return (
    <>
        <button className="btn waves-effect waves-light teal  custom-button" onClick={() => setAddOrEDit(true)}>Dodaj produkt</button>
        {addOrEdit && <ProductComponent />}
        <DataTableExtensions {...tableData}> 
                <DataTable
                columns={columns}
                data={data}
                noHeader
                defaultSortField="id"
                defaultSortAsc={false}
                pagination
                highlightOnHover
            />

            </DataTableExtensions>
        </>
  )
}

export default ProductsComponent