import React, { useEffect, useRef, useState } from 'react';
import M from 'materialize-css';
import useProductStore from './useProductStore';
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import { MagnifyingGlass } from 'react-loader-spinner';
import useModalStore from './useModalStore';
import axios from 'axios';
import config from './config';
import { useInView } from 'react-intersection-observer';

const BookModal = () => {
  const { ref, inView, entry } = useInView({
    threshold: 0,
    triggerOnce: true
  });
  const modalRef = useRef(null);
  const datePickerRef = useRef(null);
  const { pickedProduct, pickedProductDisplayName } = useProductStore();
  const { setModalState, isOpen } = useModalStore();
  const [loading, setLoading] = useState(false);
  const [ avalibility, setAvalibility] = useState(null);
  const [formData, setFormData] = useState({
    event_date: '',
    event_purchaser: '',
    event_place: '',
    email: '',
    product_name: pickedProduct,
    display_name: pickedProductDisplayName
  });


  useEffect(() => {
    const modal = document.getElementById('book-modal').classList;
    if (isOpen) {
      modal.remove('closed');
    } else {
      modal.add('closed');
    }
  }, [isOpen]);

  useEffect(() => {
    setFormData((prevData) => ({
        ...prevData,
        ['product_name']: pickedProduct,
    }));

    setFormData((prevData) => ({
      ...prevData,
      ['display_name']: pickedProductDisplayName,
  }));
  },[pickedProduct]);

  useEffect(() => {
    const options = {
      onOpenStart: () => {
        console.log('Open Start');
      },
      onOpenEnd: () => {
        console.log('Open End');
      },
      onCloseStart: () => {
        console.log('Close Start');
      },
      onCloseEnd: () => {
        console.log('Close End');
      },
      inDuration: 250,
      outDuration: 250,
      opacity: 0.5,
      dismissible: false,
      startingTop: '4%',
      endingTop: '10%',
    };

    M.Modal.init(modalRef.current, options);

    // Cleanup function to destroy the modal when the component unmounts
    return () => {
      const instance = M.Modal.getInstance(modalRef.current);
      if (instance) {
        instance.destroy();
      }
    };
  }, []); // Empty dependency array ensures the effect runs once on mount

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
        ...prevData,
        [name]: value,
    }));
  }

  const checkAndBookAsset = async (e) => {
    e.preventDefault();
    
    if (formData.event_date === '' || formData.email === '' || formData.event_purchaser === 'null' ) {
      M.toast({ html: 'Wypełnij wymagane pola: data, email, imię i nazwisko', classes: 'error-toast' })
      return;
    } 
    setLoading(true);
    try {
        setLoading(true);
        
        const response = await axios.get(`${config.API_BASE_URL}/check-avalibility`, { params: formData });
        setAvalibility(response.data.booking_exists);

        if (response.data.booking_exists) {
          M.toast({ html: 'Termin zajęty. Mimo wszystko mozesz wysłać zapytanie rezerwacyjne', classes: 'error-toast' })
        } else {
          M.toast({ html: 'Termin dostępny', classes: 'success-toast' })
        }
      } catch (error) {
        M.toast({ html: 'Wystąpił problem z pobieraniem dostępności', classes: 'error-toast' })
      } finally {
        setLoading(false);
      }
  }

  const bookProduct = async (e) => {
    e.preventDefault();

    try {
      
      const response = await axios.post(`${config.API_BASE_URL}/book`,  JSON.stringify(formData), {
        headers: {
            'Content-Type': 'application/json',
        },
    });

    
      if (response.data.booked) {
        M.toast({ html: 'Wysłano zapytanie rezerwacyjne. Poczekaj na potwierdzenie mailowe.', classes: 'success-toast', displayLength: 4000 });
        e.target.reset()
        setTimeout(function() {
        setModalState(false);
      }, 2000);
      } else {
        M.toast({ html: 'Wystąpił problem z wysłaniem zapytania', classes: 'error-toast' })
      }
    } catch (error) {
      M.toast({ html: 'Wystąpił problem ', classes: 'error-toast' })
    } finally {
    }
  }

  const closeModal = (e) => {
    e.preventDefault();

    setModalState(false);
    setLoading(false);
  };

  return (
    <div ref={modalRef} id="book-modal" className="modal bottom-sheet closed">
      <form onSubmit={bookProduct}>
      <div className="modal-content">
        <h4>Rezerwacja {pickedProductDisplayName}</h4>
        <div className='row'>
          <div className="input-field col s12 m6 l6">
            <input type='date' id='event-date' name='event_date' required onChange={handleChange}/>
            <label for="event-date">Data</label>
          </div>
          <div className="input-field col s12 m6 l6">
            <input type='text' id='event-purchaser' name='event_purchaser' required onChange={handleChange}/>
            <label for="event-purchaser">Imię i nazwisko</label>
          </div>
          
        </div>

        <div className='row'>
          <div className="input-field col s12 m6 l6">
            <input type='text' id='event-place' name='event_place' onChange={handleChange}/>
            <label for="event-place">Miejsce imprezy</label>
          </div>
          <div className="input-field col s12 m6 l6">
            <input type='email' id='email' name='email' required onChange={handleChange}/>
            <label for="email">Adres e-mail</label>
          </div>
        </div>

        <div>
          {loading && <div className='searching'><MagnifyingGlass
            visible={loading}
            height="80"
            width="80"
            ariaLabel="magnifying-glass-loading"
            wrapperStyle={{}}
            wrapperClass="magnifying-glass-wrapper"
            glassColor="#c0efff"
            color="#e15b64"
          />
            Sprawdzam dostępność</div>
          }
        </div>
      </div>
      <div className="modal-footer">
        <button className="modal-close waves-effect waves-light btn-small  custom-button" onClick={checkAndBookAsset}>Sprawdź dostępność</button>
        <button className="modal-close waves-effect waves-light btn-small  custom-button">Zarezerwuj</button>
        <button className="modal-close waves-effect waves-light btn-small" onClick={(e) => closeModal(e)}>Anuluj</button>
      </div>
      </form>
    </div>
  );
};

export default BookModal;

{/* <FullCalendar
        plugins={[dayGridPlugin]}
        initialView="dayGridMonth"
        events={[
          { title: "event 1", date: "2019-04-01" },
          { title: "event 2", date: "2019-04-02" },
        ]}
      /> */}